<template>
  <div id="pictureDatase"
       v-loading.fullscreen.lock="loading"
       element-loading-text="请求中，请稍后"
       element-loading-background="rgba(255, 255, 255, 0.7)">
    <div class="c-query">
      <el-form :model="queryParams"
               ref="queryForm"
               :inline="true"
               label-width="68px"
               style="width: 100%;">
        <el-form-item prop="alzx0">
          <el-input placeholder="请输入你要查找的内容"
                    v-model="queryParams.queryInput"
                    class="input-with-select"
                    style="width: 400px;">
            <el-select v-model="queryParams.querySelect"
                       slot="prepend"
                       placeholder="请选择">
              <el-option label="广东省信宜市"
                         value="1"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <div style="display: inline-block;float: right;">
          <el-form-item label="排序">
            <el-select v-model="queryParams.alzx1"
                       placeholder="排序"
                       clearable
                       size="small">
              <el-option v-for="dict in alzx1Options"
                         :key="dict.dictValue"
                         :label="dict.dictLabel"
                         :value="dict.dictValue" />
            </el-select>
          </el-form-item>
          <el-form-item label="免考状态"
                        prop="alzx2">
            <el-select v-model="queryParams.alzx2"
                       placeholder="免考状态"
                       clearable
                       size="small">
              <el-option v-for="dict in alzx2Options"
                         :key="dict.dictValue"
                         :label="dict.dictLabel"
                         :value="dict.dictValue" />
            </el-select>
          </el-form-item>
          <el-form-item label="正式上线"
                        prop="alzx3">
            <el-select v-model="queryParams.alzx3"
                       placeholder="正式上线"
                       clearable
                       size="small">
              <el-option v-for="dict in alzx3Options"
                         :key="dict.dictValue"
                         :label="dict.dictLabel"
                         :value="dict.dictValue" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       size="small">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="c-container-box">
      <el-row>
        <el-col v-for="(item,index) in devices"
                :key="item.id"
                :span="6">
          <el-card style="margin: 10px;"
                   v-if="pageFilter(item.title)">
            <el-row>
              <el-col :span="15">
                <el-popover placement="bottom"
                            title=""
                            width="80"
                            trigger="manual"
                            content="图片已更新"
                            :close-delay='2000'
                            v-model="pic_obj[item.id].visible"
                            style="float: top;">
                </el-popover>
                <viewer class="viewer">
                  <img :src="pic_obj[item.id].url"
                       style="width: 100%;height: 160px;">
                </viewer>
                <!-- <img src="./default.jpg" style="width: 100%;height: 180px;"> -->
              </el-col>
              <el-col :span="9"
                      style="height: 160px;background-color: #F2F2F2; position:relative;">
                <div style="margin: 0 5px 0 10px;">
                  <!-- v-if="hideTime(pic_obj[item.id])" -->
                  <p style="color:gray;font-size: 5px;text-align: center;"
                     v-if="hideTime(pic_obj[item.id].url)">
                    {{pic_obj[item.id].time}}
                  </p>
                   <el-tooltip class="item" effect="dark" :content="item.title == null || item.title ==
										'' ? item.id : item.title" placement="top-start">
                      <p class="pd-title">{{item.title == null || item.title ==
										'' ? item.id : item.title}}</p>
                   </el-tooltip>
                  <p>雨量(毫米)：{{event_datas[item.id].rainFall}}</p>
                  <p>水位(米)：{{event_datas[item.id].water}}</p>
                  <!-- <p style="position: absolute; bottom: -5px;width: 43%;">
                    <el-row>
                      <el-col :span="8"
                              style="color:#0000ff;font-size: 15px;"><i class="el-icon-view"
                           @click="eye(item)" /></el-col>
                      <el-col :span="8"
                              style="color:#0000ff;font-size: 15px;"><i class="el-icon-camera"
                           @click="camera(item.id)" /></el-col>
                      <el-col :span="8"
                              style="color:#0000ff;font-size: 15px;"><i class="el-icon-location-outline"
                           @click="location(item.id)" /></el-col>
                    </el-row>
                  </p> -->
                  <div class="bottom-btn">
                    <el-row>
                      <el-col :span="6">
                        <el-button icon="el-icon-view"
                                   size="mini"
                                   @click="eye(item)"
                                   circle></el-button>
                      </el-col>
                      <el-col :span="6">
                        <el-button icon="el-icon-camera"
                                   size="mini"
                                   @click="camera(item.id)"
                                   circle></el-button>
                      </el-col>
                      <el-col :span="6">
                        <el-button icon="el-icon-location-outline"
                                   size="mini"
                                   @click="location(item.id)"
                                   circle></el-button>
                      </el-col>
                      <el-col :span="6">
                        <el-button icon="el-icon-picture-outline"
                                   size="mini"
                                   @click="previewImgFun(item.id)"
                                   circle></el-button>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog v-dialogDrag
               :title="dialog.title"
               :close-on-click-modal="false"
               :visible.sync="dialog.flag"
               v-if="dialog.flag"
               width="80%">
      <el-tabs style="height: 600px;overflow-y: scroll;"
               type="border-card"
               v-model="dialog.active">
        <el-tab-pane label="实时数据"
                     name="realtime">
          <real-time v-if="dialog.active == 'realtime'"
                     :devices="devices"
                     :uid="deviceUid"></real-time>
        </el-tab-pane>
        <el-tab-pane label="历史免考"
                     name="historyOmit">
          <history-omit v-if="dialog.active == 'historyOmit'"></history-omit>
        </el-tab-pane>
        <el-tab-pane label="历史流量"
                     name="historyTraffic">
          <history-traffic v-if="dialog.active == 'historyTraffic'"></history-traffic>
        </el-tab-pane>
        <!-- <el-tab-pane label="历史图像"
                     name="historyImage">
          <history-image v-if="dialog.active == 'historyImage'"></history-image>
        </el-tab-pane> -->
        <el-tab-pane label="基础信息"
                     name="basicInfo">
          <basic-info v-if="dialog.active == 'basicInfo'"
                      :uid="deviceUid"></basic-info>
        </el-tab-pane>
        <el-tab-pane label="工程图库"
                     name="projectImage">
          <project-image v-if="dialog.active == 'projectImage'"
                         :uid="deviceUid"></project-image>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog :title="dialog1.title"
               :close-on-click-modal="false"
               :visible.sync="dialog1.flag"
               width="80%">
      <div style="width: 100%;height: 600px;">
        <Map style="width: 100%;height: auto; top: 55px !important;"></Map>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import Map from '@/components/VMap.vue'; //地图
import log from '../../common/log';
const realTime = () => import("@/views/second/components/realtime")
const historyOmit = () => import("@/views/second/components/historyOmit")
const historyTraffic = () => import("@/views/second/components/historyTraffic")
const basicInfo = () => import("@/views/second/components/basicInfo")
const historyImage = () => import("@/views/second/components/historyImage")
const projectImage = () => import("@/views/second/components/projectImage")
const ROOT_PATH = "/media"; //图片根路径

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': true,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
    'url': 'data-source'
  }
})

export default {
  components: {
    realTime,
    historyOmit,
    historyTraffic,
    basicInfo,
    historyImage,
    projectImage,
    Map
  },
  data () {
    return {
      devices: [],
      pic_obj: {},
      profile: {},
      event_datas: {},
      time: '',
      deviceUid: '', //操作的设备
      dialog: {
        flag: false,
        title: '',
        active: 'realtime',
        // active: 'projectImage'
      },
      dialog1: {
        flag: false,
        title: '',
      },
      queryParams: {
        querySelect: '',
        queryInput: '',
        alzx1: '',
        alzx2: '',
        alzx3: '',
        pageNum: 1,
        pageSize: 10,
      },
      alzx1Options: [{
        dictLabel: '全部',
        dictValue: '全部'
      },
      {
        dictLabel: '1',
        dictValue: '1'
      },
      {
        dictLabel: '2',
        dictValue: '2'
      }
      ],
      alzx2Options: [{
        dictLabel: '全部',
        dictValue: '全部'
      },
      {
        dictLabel: '是',
        dictValue: '是'
      },
      {
        dictLabel: '否',
        dictValue: '否'
      }
      ],
      alzx3Options: [{
        dictLabel: '全部',
        dictValue: '全部'
      },
      {
        dictLabel: '是',
        dictValue: '是'
      },
      {
        dictLabel: '否',
        dictValue: '否'
      }
      ],
      loading: false,
    }
  },
  computed: {

  },
  watch: {
    "$store.state.profile": {
      handler: function () {
        let profile = this.$store.state.profile;
        this.profile = profile;
      }
    },
    // 监听节点数据切换
    "$store.getters.getCurDevices": {
      handler: function (newVal, oldVal) {
        // 设备数据
        let deviceData = this.$store.getters.getCurDevices;
        this.devices = deviceData;

      }
    },
  },
  methods: {
    eye (val) {
      console.log(val)
      this.deviceUid = val.uid
      this.dialog.flag = true;
      this.dialog.title = val.title;
    },
    camera (val) {
      let json = {
        "cmd": "pushEvent",
        "uid": val,
        "tag": "snap",
      }
      this.ws.send(json);
    },
    location (val) {
      this.dialog1.flag = true;
      this.dialog1.title = val;
    },
    test (val) {
      if (val != null && val.indexOf('default') == -1) {
        let arr = val.split('.')[0].split('/');
        let str = arr[arr.length - 1];
        let arr1 = str.split(' ');
        return arr1[0] + ' ' + arr1[1].substr(0, 2) + ':' + arr1[1].substr(2, 2) + ':' + arr1[1].substr(4, 2);
      }
    },
   previewImgFun(e){
     // 获取全部设备中指定的一个设备
     let devices = this.$store.getters.getCurDevices;
     if(devices.length > 0){
      let  devicesInfo =  devices.filter(item=>item.uid == e)
      this.$store.commit('setCurTreeData', devicesInfo);
      this.$router.push({path:'/public/picture_data'})
     }
    //  this.$store.commit('setCurTreeData', [nodeData]);
    //  this.$router.push({path:'/public/picture_data',query:{uid:e}})
   }, 
    /* device_datas() {
      let json = {
        "cmd": "devices"
      }
      this.ws.send(json);
    }, */
    tap_snapUrl () {
      var uids = '';
      this.devices.forEach((item) => {
        uids += item.id + ",";
      })
      let json = {
        "cmd": "snapUrl",
        "uids": uids
      }
      this.ws.send(json);
    },
    event_data () {
      var uids = '';
      this.devices.forEach((item) => {
        uids += item.id + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      };
      this.ws.send(json);
    },
    pageFilter (title) {
      if (this.queryParams.queryInput != '' && this.queryParams.queryInput != null) {
        if (title == null) return false;

        if (title.indexOf(this.queryParams.queryInput) > -1) {
          return true;
        } else {
          return false;
        }
      }
      else return true;

    },
    hideTime (url) {
      if (url.indexOf('default') > -1) return false;
      else return true;
    },
    formatter (thistime, fmt) {
      let $this = new Date(thistime)
      let o = {
        'M+': $this.getMonth() + 1,
        'd+': $this.getDate(),
        'h+': $this.getHours(),
        'm+': $this.getMinutes(),
        's+': $this.getSeconds(),
        'q+': Math.floor(($this.getMonth() + 3) / 3),
        'S': $this.getMilliseconds()
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, ($this.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k])
            .length)))
        }
      }
      return fmt
    },
    getDevice (uid) {
      let obj = this.common.cFindObj(this.devices, "id", uid);
      return obj;
    },
    setData () {
      var obj = {};
      obj.rainFall = '';
      obj.water = '';
      obj.radarRaw = '';
      for (var i = 0; i < this.devices.length; i++) {
        this.event_datas[this.devices[i].uid] = obj;
      }
    }
  },
  mounted () {
    this.user = this.common.cGetUser();
    // this.device_datas();
    // 所有模块
    let profile = this.$store.state.profile;
    this.profile = profile;
    // 设备数据
    let deviceData = this.$store.getters.getCurDevices;
    this.devices = deviceData;
    this.setData();
    this.tap_snapUrl();
    this.event_data();

    this.ws.addCallback('devicesAck', (res) => {
      this.devices = res.devices;
      this.setData();
      this.tap_snapUrl();
      this.event_data();
    })

    this.ws.addCallback('snapUrlAck', (res) => {
      delete res.cmd;
      let arr = Object.keys(res);
      for (var a of arr) {
        if (a != 'cmd')
          res[a].visible = false;
      }
      this.pic_obj = res;
    })

    this.ws.addCallback('snapUrlReady', (res) => {
      let arr = Object.keys(res);
      res[arr[1]].visible = true;
      this.pic_obj[arr[1]] = res[arr[1]];
      setTimeout(() => {
        res[arr[1]].visible = false;
      }, 2000)
    })

    this.ws.addCallback('event', (res) => {
      var that = this;
      if (res.type != "sensor") {
        return;
      }
      let uidData = this.getDevice(res.uid);
      let data = this.parseProfile.parseData(uidData.model, res.data ? res.data : "");
      var obj = {};
      data.forEach((item) => {
        if (item.key == 'rainFall') {
          obj.rainFall = item.value;
        }

        if (item.key == 'water') {
          obj.water = item.value;
        }
      })
      that.event_datas[res.uid] = obj;
      this.$forceUpdate();
    })

  }
}
</script>

<style lang="scss">
.viewer {
  display: grid;
  /* // grid-template-columns: repeat(auto-fill, minmax($img_w + 40px, 1fr)); //minmax(最小宽度，最大宽度) */
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}

/* .map_div {
		.map {
			top: 55px !important;
		}
	} */

#pictureDatase {
  .el-card__body {
    padding: 10px;
  }

  .el-form-item {
    height: 18px;
  }

  .pd-title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  .bottom-btn {
    width: 100%;
    position: absolute;
    bottom: 8px;
  }
}

.el-popover--plain {
  position: absolute;
  top: 50px;
  left: 30px;
  background-color: #c0c4cc;
  color: white;
  text-align: center;
}
</style>