<!-- 可视化界面——地图 -->
<template>
  <div id="vMap">
    <baidu-map class="map" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" @ready="handler">
      <!-- marker -->
      <bm-marker v-for="(item, index) of dataArr" :position="{lng: item.lng, lat: item.lat}" :key="index" :icon="{url:item.icon, size: {width: 30, height: 30}}" @click="markerClick(item)">
        <bm-label :content="item.deviceName?item.deviceName:item.deviceUid" :position="{lng: item.lng, lat: item.lat}" :offset="{width: 20, height: -20}" :labelStyle="{color: 'red', fontSize : '14px'}" title="点击可查看详情" />
        <!-- animation="BMAP_ANIMATION_BOUNCE" -->
        <bm-info-window v-if="item.infoWindow.show" :show="item.infoWindow.show" @close="closeWindow(item)">
          <template>
            <div class="info-window" style="width: 600px; height: 200px;">
              <div class="left">
                <span style="font-size:17px;font-weight: bold;display: inline-block;margin-bottom: 20px;text-align: center;">{{item.deviceName}}</span>
                <span>雨量：{{item.rainFall}}毫米</span>
                <!-- <span>流量：100m³</span> -->
                <!-- <span>流速：35m³/s</span> -->
                <span>水位：{{item.water}}米</span>
                <!-- <span>PH值：6.45</span> -->
                <!-- <span>阀门开度：45度</span> -->
              </div>
              <div class="right">
                <!-- <img width="100%" height="100%" src="../assets/test1.jpg" /> -->
                <!-- <video muted controls autoplay="autoplay" loop="loop"
									style="width: 100%; height: 100%;">
									<source src="../assets/test.mp4">
								</video> poster="http://open.ys7.com/asdf.jpg" -->
                <video id="player" ref="cvideo" muted autoplay controls playsInline webkit-playsinline style="width: 100%; height: auto;" :src="item.videoUrl">
                  <source :src="item.videoUrl" type="application/x-mpegURL">
                </video>
              </div>
            </div>
          </template>
        </bm-info-window>
      </bm-marker>
    </baidu-map>
    <!-- 设备统计 -->
    <!-- <div class="count">
			<div class="item">
				<span>监测点个数</span>
				<span>24</span>
			</div>
			<div class="item" v-for="item of countItem">
				<span>{{item.title}}</span>
				<span>{{item.value}}</span>
			</div>
		</div> -->
    <!-- 图例 -->
    <div class="legend">
      <p>图例</p>
      <div class="item">
        <span></span>
        <span>可能发生</span>
      </div>
      <div class="item">
        <span></span>
        <span>可能性较大</span>
      </div>
      <div class="item">
        <span></span>
        <span>可能性大</span>
      </div>
      <div class="item">
        <span></span>
        <span>可能性很大</span>
      </div>
    </div>
    <!-- 设备详情 -->
    <!-- <div class="marker-info">
			<p class="info-title">设备信息</p>
			<p><span>●</span>设备名称：{{deviceInfo.deviceName}}</p>
			<p><span>●</span>设备UID：{{deviceInfo.deviceUid}}</p>
			<p><span>●</span>设备状态：在线</p>
			<p><span>●</span>更新时间：2021-03-22 11:02:01</p>
			<p><span>●</span>设备坐标：{{deviceInfo.lng}}，{{deviceInfo.lat}}</p>
		</div> -->
    <!-- 视频 -->
    <!-- <div class="dialog" style="display: none;">
			<Video></Video>
		</div> -->
  </div>
</template>

<script>
import MapTheme from '../common/map_theme.js';
import Video from './Video.vue';
import ezuikit from '../common/hk1.3/1.3/ezuikit.js';

export default {
  data() {
    return {
      center: {
        lng: 0,
        lat: 0
      },
      infoWindow: {
        width: 800,
        height: 500
      },
      zoom: 15,
      dataArr: [],
      deviceDataArr: [],
      deviceInfo: {},

      countItem: [{
        title: '报警点个数',
        value: '11'
      }, {
        title: '可能发生',
        value: '6'
      }, {
        title: '可能性较大',
        value: '2'
      }, {
        title: '可能性大',
        value: '2'
      }, {
        title: '可能性很大',
        value: '1'
      }],
      videoData: {
        url: require('../assets/test.mp4'),
        id: ''
      },
      selectorItem: '',
      videoUrl: 'https://open.ys7.com/v3/openlive/G57171331_1_1.m3u8?expire=1670318956&id=389831012889751552&t=57706c35fbed962f37b9e183554bc33f2282c79a1cf8f554b2437470102caeab&ev=100'
    }
  },
  props: ["mapsource"],
  components: {
    Video
  },
  methods: {
    closeWindow(item) {
      item.infoWindow.show = false;
    },
    reload(reloadsource) {
      this.dataArr = reloadsource
    },
    handler({
      BMap,
      map
    }) {
      // map.setMapStyle({
      // 	styleJson: MapTheme.getMapTheme()
      // });
      this.dataArr = this.mapsource
      var view = map.getViewport(eval(this.dataArr));
      this.zoom = view.zoom;
      this.center = view.center;
    },
    // 发送视频
    gpsDataRequest(uid) {
      // let json = {
      // 	"cmd": "dataStore",
      // 	"id": uid,
      // 	"tag": "video"
      // }
      // this.ws.send(json);
      // 兼容华为云视频和海康云视频
      let json;
      if (this.common.featureEnabled('huaweiVideo')) {
        // 华为云视频
        json = {
          "cmd": "videoUrl",
          "uid": uid,
          "type": this.common.videoType(),  // 支持hls,rtmp和flv,不传时默认为hls
        }
        console.log('---华为云视频参数---', json)
      } else {
        // 海康云视频
        json = {
          "cmd": "dataStore",
          "id": uid,
          "tag": "video"
        }
        console.log('---海康云视频参数---', json)
      }

      this.ws.send(json);

    },
    // 点击地图上的设备
    markerClick(item) {
      this.selectorItem = item;
      this.gpsDataRequest(item.deviceUid);
      // this.deviceInfo = item;
    },
    // 视频回调
    videoUrlAckFun(res, num) {
      this.dataArr.map((element) => {
        if (element.deviceUid != this.selectorItem.deviceUid) {
          element.infoWindow.show = false;
        } else {
          this.$parent.data1.map((pelement) => {
            if (pelement.uid == this.selectorItem.deviceUid) {
              if (pelement.water != undefined && pelement.water != '' && pelement.water != null) {
                element.water = pelement.water;
              }
              if (pelement.rainFall != undefined && pelement.rainFall != '' && pelement.rainFall != null) {
                element.rainFall = pelement.rainFall;
              }
            }
          })
          element.infoWindow.show = true;
          if (num == 1) {  // 海康
            element.videoUrl = res.items[0].data.url || ''
          } else { // 华为
            element.videoUrl = res.url || ''
          }

        }
      })
      setTimeout(() => {
        let player = new EZUIPlayer('player');
      }, 1000)
    },
    // 监听
    onMsgFun() {
      // 获取视频信息 【华为】
      this.ws.addCallback('videoUrlAck', (res) => {
        this.videoUrlAckFun(res, 2)
      })
      // 获取视频信息 【海康】
      this.ws.addCallback('dataStoreAck', (res) => {
        if (res.tag == 'video') this.videoUrlAckFun(res, 1)
      });
    }
  },
  mounted() {
    // let positions = [
    // 	'113.797052,22.738238', '113.793027,22.615522', '113.972976,22.442464', '113.575709,22.904527',
    // 	'114.408762,22.688098', '114.434633,22.532225', '113.833271,22.529021', '114.281705,22.615522',
    // 	'113.991373,22.510325', '114.106931,22.42055', '114.489825,22.62086', '113.728062,22.587228',
    // 	'114.032767,22.652349', '114.094858,22.688632', '114.164997,22.611252', '113.921808,22.727038'
    // ];
    // let positions = [
    // 	'111.005424,22.4053', '111.023534,22.41118', '111.040494,22.41519', '111.033308,22.438976',
    // 	'110.991914,22.425614', '110.990764,22.410646', '110.995076,22.391399', '111.039632,22.382844',
    // 	'110.994214,22.345945', '111.011461,22.326422', '111.033308,22.335515', '111.040207,22.346213',
    // 	'111.056879,22.355305', '111.024397,22.314118', '111.031296,22.287367', '111.081601,22.356107',
    // 	'110.838124,22.310374', '110.848185,22.33712', '110.848185,22.33712', '110.947645,22.277468',
    // ];
    // let deviceNames = ['平塘镇沙子河', '东江河池洞段', '朱砂镇朱沙河', '黄华江钱排河上游', '杨梅河', '怀乡河', '木瓜河', '石垌河'];
    // let dataArr = new Array();
    // for (let i = 0; i < positions.length; i++) {
    // 	let icon = '';
    // 	if (i >= 0 && i <= 5) {
    // 		icon = require('../assets/marker1.png');
    // 	} else if (i > 5 && i <= 7) {
    // 		icon = require('../assets/marker2.png');
    // 	} else if (i > 15 && i <= 18) {
    // 		icon = require('../assets/marker3.png');
    // 	} else if (i > 18 && 1 <= 20) {
    // 		icon = require('../assets/marker4.png');
    // 	} else {
    // 		icon = require('../assets/marker.png');
    // 	}
    // 	let show = false;
    // 	if (i == positions.length - 1) {
    // 		show = true;
    // 	}
    // 	dataArr.push({
    // 		lng: positions[i].substring(0, positions[i].indexOf(",")),
    // 		lat: positions[i].substring(positions[i].indexOf(",") + 1, positions[i].length),
    // 		deviceName: deviceNames[Math.floor(Math.random() * 7)],
    // 		deviceUid: "UID000" + this.common.cRandomCode(),
    // 		icon: icon,
    // 		infoWindow: {
    // 			show: show
    // 		}
    // 	})
    // }
    // this.dataArr = dataArr;
    this.deviceDataArr = this.common.cGetDeviceData();
    this.onMsgFun();



    // let logo = this.common.cGetLogo();
    // this.videoUrl = logo.wallVideoUrl;
    // this.videoUrl = "https://hls01open.ys7.com/openlive/be491910110e4a2091c5920e1748e029.hd.m3u8";

  }
}
</script>

<style lang="scss">
#vMap {
  height: 100%;

  .map {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  // 设备统计
  .count {
    position: absolute;
    top: 10px;
    left: 10px;

    $colorlist: #2746a2, #b4002d, #fffc47, #fc6c22, #fc0011, #b5005e;

    @for $i from 1 to 7 {
      .item:nth-child(#{$i}) {
        width: 180px;
        padding: 16px;
        padding-right: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #071124;
        box-shadow: inset 0 0 8px 0 nth($colorlist, $i);
        margin-bottom: 10px;
        border-radius: 2px;

        &:first-child {
          background-color: #146bd2;
        }

        span {
          &:first-child {
            font-size: 14px;
          }

          &:last-child {
            font-size: 28px;
            font-weight: bold;
          }
        }
      }
    }
  }

  // 图例
  .legend {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 160px;
    background-color: #081631;

    p {
      text-align: center;
    }

    $colorlist: #b4002d, #fffc47, #fc6c22, #fc0011, #b5005e;

    @for $i from 1 to 6 {
      .item:nth-child(#{$i}) {
        margin: 10px 30px;

        span {
          &:first-child {
            display: inline-block;
            width: 20px;
            height: 10px;
            background-color: nth($colorlist, $i);
          }

          &:last-child {
            margin-left: 10px;
          }
        }
      }
    }
  }

  // 设备详情
  .marker-info {
    width: 250px;
    height: 200px;
    position: absolute;
    left: 2%;
    bottom: 2%;
    padding: 10px;
    background-color: rgba($color: #0c72f8, $alpha: 0.2);
    text-shadow: 1px 1px 0 $color_black;

    .info-title {
      font-weight: bold;
      color: #ffcc00;
    }

    span {
      margin-right: 8px;
      color: #ffcc00;
    }
  }

  .info-window {
    height: 120px;
    color: #ffffff;
    display: flex;
    flex-direction: row;

    .left {
      width: 150px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      span {
        line-height: 25px;
      }
    }

    .right {
      flex: auto;
    }
  }

  // 视频dialog
  .dialog {
    position: absolute;
    width: 80%;
    min-width: 450px;
    height: 600px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #ffffff;
  }

  // 重写百度地图infowindow样式

  // .BMap_pop div:nth-child(1) div,
  // .BMap_pop div:nth-child(3) div,
  // .BMap_pop div:nth-child(5) div,
  // .BMap_pop div:nth-child(7) div,
  // .BMap_pop .BMap_top,
  // .BMap_pop .BMap_center,
  // .BMap_pop .BMap_bottom {
  // 	background-color: rgba($color: #0C72F8, $alpha: 0.8) !important;
  // 	border: 0 !important;
  // }

  $color: rgba(
    $color: #081631,
    $alpha: 1,
  );

  .BMap_pop .BMap_top {
    background: $color !important;
    border: 0 !important;
  }

  .BMap_pop .BMap_center {
    border: 0 !important;
    background: $color !important;
  }

  .BMap_pop .BMap_bottom {
    border: 0 !important;
    background: $color !important;
  }

  .BMap_pop div:nth-child(3) {
    background: transparent !important;
  }

  .BMap_pop div:nth-child(3) div {
    background: $color !important;
    border: 0 !important;
  }

  .BMap_pop div:nth-child(1) {
    background: transparent !important;
    border: 0 !important;
  }

  .BMap_pop div:nth-child(1) div {
    background: $color !important;
  }

  .BMap_pop div:nth-child(5) {
    background: transparent !important;
    border: 0 !important;
  }

  .BMap_pop div:nth-child(5) div {
    background: $color !important;
  }

  .BMap_pop div:nth-child(7) {
    background: transparent !important;
    left: 226px;
  }

  .BMap_pop div:nth-child(7) div {
    background: $color !important;
  }

  img[src="https://api.map.baidu.com/images/iw_close1d3.gif"]
  {
    display: none;
  }

  /*替换关闭按钮*/
  img[src="http://api0.map.bdimg.com/images/iw_close1d3.gif"]
  {
    content: url("http://vino.test.bidostar.cn/iw_close1d3.png");
  }

  /*替换剪头*/
  img[src="http://api0.map.bdimg.com/images/iw3.png"]
  {
    opacity: 0.7;
    margin-top: -692px !important;
    filter: alpha(opacity=70);
    content: url("http://vino.test.bidostar.cn/iw3.png");
  }

  /*替换箭头*/
  img[src="http://api0.map.bdimg.com/images/iw3.png"]
  {
    display: none !important;
  }

  img[src="https://api.map.baidu.com/images/iw3.png"]
  {
    display: none !important;
  }
}
</style>
